import {css} from '@emotion/react'
import {useWindowSize} from '@kensho/tacklebox'
import {Fragment, useMemo, useRef, useState} from 'react'

import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../../styles/breakpoints'
import Audio from '../../../components/Audio'
import Select from '../../../components/Select'
import {TwoColumnContent} from '../../../components/content'
import TextTyper, {DEFAULT_TYPING_SPEED} from '../../../components/TextTyper'
import durationToMinutesAndSeconds from '../../../utils/durationToMinutesAndSeconds'
import {
  legalCss,
  labelCss,
  h4Body1Css,
  h4Body2Css,
  hBoldCss,
  blackTextCss,
  darkBlueTextCss,
} from '../../../styles/common'

import AudioItem from './AudioItem'
import transcriptionSamples, {Token, TranscriptSample} from './transcriptionSamples'

const audioSamplesCss = css`
  margin-bottom: 50px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-top: 45px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-top: 35px;
  }
`

const hiddenAccessibleCss = css`
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
`

const textContainerCss = css`
  display: flex;
  margin-top: 30px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-top: 13px;
  }
`

const flexFullCss = css`
  flex: 1 1 100%;
`

const audioTracksCss = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 100px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    padding-left: 0px;
  }
`

const transcriptsCss = css`
  overflow: hidden;
  padding-top: 8px;
  padding-left: 45px;
  border-left: 1px solid rgba(0, 0, 0, 0.5);

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-top: 40px;
    padding: 47px 0px 0px 0px;
    border-left: none;
    border-top: 1px solid rgba(0, 0, 0, 0.5);
  }

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-top: 21px;
    padding-top: 41px;
  }
`

const competitionTranscriptionCss = css`
  padding-top: 140px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    padding-top: 60px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding-top: 40px;
  }
`

const cursorCss = css`
  position: relative;
  min-height: 35px;
  padding-right: 4px;
  max-width: 100%;
  flex: 1 1 0px;

  &::after {
    content: '';
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    border-right: 2px solid #000;
    animation: blink 1s steps(1) infinite;
  }

  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }
`

const transcriptTextCss = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  direction: rtl;
`

const listCss = css`
  margin: 0 0 5px 0;
  list-style-type: none;
  padding: 0;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-bottom: 10px;
  }
`

const selectCss = css`
  margin: 0 auto 40px auto;
  width: 100%;
`

const nowPlayingCss = css`
  margin-top: 14px;
  align-self: center;
  display: inline-block;
`

const nowPlayingLabelCss = css`
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-align: center;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    text-align: left;
    margin-bottom: 15px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    text-align: center;
    margin-bottom: 0px;
  }
`

const nowPlayingNameCss = css`
  letter-spacing: 0.1em;
  text-align: center;
`

const fullWidthCss = css`
  flex: 1 0 100%;
`

const fullTranscriptContainerCss = css`
  display: block;
  overflow: auto;
  max-width: none;
`

const fullTranscriptCss = css`
  overflow: visible;
  direction: ltr;
  text-align: left;
`

function getTextAtTime(transcript: string, tokens: Token[], time: number): React.ReactNode[] {
  return tokens.reduce<React.ReactNode[]>((acc, token) => {
    if (time > token.endTime) {
      acc.push(
        <Fragment key={`${transcript}:${token.startTime}`}>
          <TextTyper
            css={token.styles}
            typingSpeed={Math.min(
              Math.floor((token.endTime - token.startTime) / token.content.length),
              DEFAULT_TYPING_SPEED
            )}
          >
            {token.content}
          </TextTyper>{' '}
        </Fragment>
      )
    }
    return acc
  }, [])
}

export default function AudioSamples(): JSX.Element {
  const [activeSample, setActiveSample] = useState(transcriptionSamples[0])
  const [currentTimeMs, setCurrentTimeMs] = useState(0)
  const [audioSupported, setAudioSupported] = useState(true)
  const [muted, setMuted] = useState(true)

  const {width} = useWindowSize()
  const audioRef = useRef<HTMLAudioElement | null>(null)

  const currentScribeTokens = useMemo(
    () =>
      getTextAtTime(
        activeSample.name,
        activeSample.scribe,
        audioSupported
          ? currentTimeMs
          : // display full transcript when audio playback is unavailable
            1000 * 60 * 60
      ),
    [activeSample?.scribe, activeSample?.name, currentTimeMs, audioSupported]
  )
  const currentCompetitionTokens = useMemo(
    () =>
      getTextAtTime(
        activeSample.name,
        activeSample.competition,
        audioSupported
          ? currentTimeMs
          : // display full transcript when audio playback is unavailable
            1000 * 60 * 60
      ),
    [activeSample?.competition, activeSample?.name, currentTimeMs, audioSupported]
  )

  return (
    <TwoColumnContent
      css={audioSamplesCss}
      left={
        <div css={audioTracksCss}>
          {width > BREAKPOINT_LARGE || width < BREAKPOINT_SMALL ? (
            <ul css={listCss}>
              {transcriptionSamples.map((sample) => (
                <AudioItem
                  key={sample.name}
                  name={sample.name}
                  duration={sample.duration / 1000}
                  selected={sample === activeSample}
                  onClick={(): void => {
                    if (audioRef.current) audioRef.current.currentTime = 0
                    setCurrentTimeMs(0)
                    setActiveSample(
                      transcriptionSamples.find((s) => s.name === sample.name) as TranscriptSample
                    )
                  }}
                />
              ))}
            </ul>
          ) : (
            <>
              <p css={[legalCss, hBoldCss, nowPlayingLabelCss, blackTextCss]}>Now Playing</p>
              <Select
                css={selectCss}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  if (audioRef.current) audioRef.current.currentTime = 0
                  setCurrentTimeMs(0)
                  setActiveSample(
                    transcriptionSamples.find(
                      (s) => s.name === event.target.value
                    ) as TranscriptSample
                  )
                }}
              >
                {transcriptionSamples.map((sample) => (
                  <option key={sample.name} value={sample.name}>
                    {`${sample.name} (${durationToMinutesAndSeconds(sample.duration / 1000)})`}
                  </option>
                ))}
              </Select>
            </>
          )}
          <Audio
            key={activeSample.audio}
            ref={audioRef}
            controls
            loop
            autoPlay
            muted={muted}
            id="scribe-audio"
            src={activeSample.audio}
            onTimeUpdate={(): void =>
              setCurrentTimeMs(Math.floor((audioRef.current?.currentTime || 0) * 1000))
            }
            onMutedUpdate={(nextMuted) => setMuted(nextMuted)}
            onError={() => setAudioSupported(false)}
          />
          {(width > BREAKPOINT_LARGE || width < BREAKPOINT_SMALL) && (
            <div css={nowPlayingCss}>
              <p css={[labelCss, hBoldCss, darkBlueTextCss, nowPlayingLabelCss]}>Now Playing</p>
              <p css={[labelCss, nowPlayingNameCss]}>{activeSample.name}</p>
            </div>
          )}
        </div>
      }
      right={
        <div css={transcriptsCss}>
          <div>
            <h3 css={[h4Body1Css, hBoldCss, blackTextCss]}>Kensho Scribe</h3>
            <div css={hiddenAccessibleCss}>{currentScribeTokens}</div>
            <div css={[textContainerCss, !audioSupported && fullTranscriptContainerCss]}>
              <div css={audioSupported ? cursorCss : fullWidthCss}>
                <div
                  aria-hidden
                  css={[
                    transcriptTextCss,
                    h4Body2Css,
                    blackTextCss,
                    !audioSupported && fullTranscriptCss,
                  ]}
                >
                  {currentScribeTokens}
                </div>
              </div>
              <div css={flexFullCss} />
            </div>
          </div>
          <div css={competitionTranscriptionCss}>
            <h3 css={[h4Body1Css, hBoldCss, blackTextCss]}>The Competition</h3>
            <div css={hiddenAccessibleCss}>{currentCompetitionTokens}</div>
            <div css={[textContainerCss, !audioSupported && fullTranscriptContainerCss]}>
              <div css={audioSupported ? cursorCss : fullWidthCss}>
                <div
                  aria-hidden
                  css={[
                    transcriptTextCss,
                    h4Body2Css,
                    blackTextCss,
                    !audioSupported && fullTranscriptCss,
                  ]}
                >
                  {currentCompetitionTokens}
                </div>
              </div>
              <div css={flexFullCss} />
            </div>
          </div>
        </div>
      }
    />
  )
}
