import {css} from '@emotion/react'
import {forwardRef, useCallback, useEffect, useRef, useState} from 'react'

import {BREAKPOINT_LARGE} from '../../styles/breakpoints'
import muteIcon from '../../assets/icons/mute.svg'
import pauseIcon from '../../assets/icons/pause.svg'
import whitePlayIcon from '../../assets/icons/white-play.svg'
import unmuteIcon from '../../assets/icons/unmute.svg'
import useMergedRef from '../../hooks/useMergedRef'
import durationToMinutesAndSeconds from '../../utils/durationToMinutesAndSeconds'
import {blackTextCss, labelCss} from '../../styles/common'

interface AudioProps extends React.HTMLProps<HTMLAudioElement> {
  onMutedUpdate?: (muted: boolean) => void
}

const audioCss = css`
  display: flex;
  position: relative;
  width: 100%;
  max-width: 483px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    max-width: 753px;
  }
`

const muteContainerCss = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const buttonCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #007694;
  border: none;
  width: 60px;
  height: 60px;

  &:hover {
    background-color: #004d61;
  }

  &:active {
    background-color: #6d6d6d;
  }
`

const playIconCss = css`
  height: 30px;
  width: 30px;
`

const muteIconCss = css`
  width: 30px;
  height: auto;
`

const seekerContainerCss = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0px 19px;
`

const seekerCss = css`
  display: flex;
  align-items: center;
  padding: 0px 10px;
  height: 60px;
  width: 100%;
  background: #007694;

  & > input[type='range'] {
    width: 100%;
  }

  /* reset native styles */

  & > input[type='range'] {
    -webkit-appearance: none;
    background: transparent;
    appearance: none;
    cursor: pointer;
  }

  & > input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  & > input[type='range']:focus {
    outline: none;
  }

  & > input[type='range']::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  /* thumb styles */

  & > input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    margin-top: -9px;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background: #fff;
    cursor: pointer;
  }

  & > input[type='range']::-moz-range-thumb {
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background: #fff;
    cursor: pointer;
    border: none;
  }

  & > input[type='range']::-ms-thumb {
    height: 20px;
    width: 20px;
    border-radius: 100%;
    border: none;
    background: #fff;
    cursor: pointer;
  }

  /* track styles */

  & > input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: #fff;
  }

  & > input[type='range']::-moz-range-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: #fff;
  }

  & > input[type='range']::-ms-fill-lower {
    background: #fff;
    height: 2px;
  }

  & > input[type='range']::-ms-fill-upper {
    background: #fff;
    height: 2px;
  }
`

const muteLabelCss = css`
  margin-top: 7px;
  font-size: 10px;
`

const timestampsCss = css`
  display: flex;
  justify-content: space-between;
  margin-top: 7px;
`

const timeCss = css`
  font-size: 10px;
`

const hiddenAccessibleCss = css`
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
`

const errorCss = css`
  height: 50px;
  display: flex;
  align-items: center;
`

let canAutoPlay = false

function Audio(
  {
    className,
    children,
    controls,
    autoPlay,
    id,
    muted: defaultMuted,
    onTimeUpdate,
    onMutedUpdate,
    ...audioProps
  }: AudioProps,
  ref: React.Ref<HTMLAudioElement | null>
): JSX.Element {
  const innerRef = useRef<HTMLAudioElement | null>(null)
  const mergedRef = useMergedRef(ref, innerRef)

  const [currentTime, setCurrentTime] = useState(0) // seconds
  const [muted, setMuted] = useState(defaultMuted)
  const [playing, setPlaying] = useState(false)
  const [simulatedAutoPlay, setSimulatedAutoPlay] = useState(false)
  const [duration, setDuration] = useState(0) //  seconds
  const [error, setError] = useState('')

  useEffect(() => {
    const onPlay = (): void => setPlaying(true)
    const onPause = (): void => setPlaying(false)
    const onDurationChange = (): void => setDuration(innerRef.current?.duration || 0)
    const onError = (): void => setError(`Your browser doesn't support audio playback`)

    const audioEle = innerRef.current
    audioEle?.addEventListener('play', onPlay)
    audioEle?.addEventListener('pause', onPause)
    audioEle?.addEventListener('durationchange', onDurationChange)
    audioEle?.addEventListener('error', onError)

    return () => {
      audioEle?.removeEventListener('play', onPlay)
      audioEle?.removeEventListener('pause', onPause)
      audioEle?.removeEventListener('durationchange', onDurationChange)
      audioEle?.removeEventListener('error', onError)
    }
  }, [])

  const onPageClick = useCallback(() => {
    if (simulatedAutoPlay) innerRef.current?.play()
    setSimulatedAutoPlay(false)
    canAutoPlay = true
  }, [simulatedAutoPlay])

  useEffect(() => {
    if (simulatedAutoPlay) window.addEventListener('click', onPageClick)

    return () => {
      window.removeEventListener('click', onPageClick)
    }
  }, [simulatedAutoPlay, onPageClick])

  // detect if this audio element can autoplay, fake it if not
  // browsers block autoplay of media elements before a user interacts with a page
  // this wouldn't be necessary if react mounted an audio element with muted attribute
  // https://github.com/facebook/react/issues/10389
  const autoPlayPromise = useRef<Promise<void> | null>(null)
  useEffect(() => {
    let isCurrent = true
    if (
      !canAutoPlay &&
      autoPlay &&
      muted &&
      currentTime === 0 &&
      innerRef.current &&
      !autoPlayPromise.current
    ) {
      autoPlayPromise.current = innerRef.current.play()
      if (autoPlayPromise.current) {
        autoPlayPromise.current
          .then(() => {
            canAutoPlay = true
          })
          .catch(() => {
            if (isCurrent) {
              setPlaying(true)
              setSimulatedAutoPlay(true)
            }
          })
      } else {
        // in IE .play() doesn't return anything but autoplay is not blocked
        canAutoPlay = true
        setSimulatedAutoPlay(false)
      }
    } else if (canAutoPlay) {
      setSimulatedAutoPlay(false)
    }

    return () => {
      isCurrent = false
    }
  }, [autoPlay, muted, currentTime])

  // advance currentTime state when simulatedAutoPlay = true
  useEffect(() => {
    let simulatedAutoPlayInterval: number
    if (simulatedAutoPlay) {
      simulatedAutoPlayInterval = window.setInterval(
        () =>
          setCurrentTime((prevTime) => {
            let nextTime = prevTime + 0.1
            if (nextTime >= duration) nextTime = 0
            return nextTime
          }),
        100
      )
    }

    return () => {
      window.clearInterval(simulatedAutoPlayInterval)
    }
  }, [simulatedAutoPlay, duration, onTimeUpdate])

  // sync audio element currentTime with state when simulatedAutoPlay = true
  useEffect(() => {
    if (simulatedAutoPlay && innerRef.current) innerRef.current.currentTime = currentTime
  }, [simulatedAutoPlay, currentTime])

  const handlePlaybackClick = useCallback(
    (event: React.MouseEvent<HTMLElement>): void => {
      event.stopPropagation()
      setSimulatedAutoPlay(false)
      if (innerRef.current) {
        if (playing) {
          innerRef.current.pause()
        } else {
          innerRef.current.play()
        }
      }
    },
    [playing]
  )

  const handleMuteClick = useCallback((): void => {
    setMuted((prevMuted) => !prevMuted)
    if (onMutedUpdate) onMutedUpdate(!muted)
  }, [onMutedUpdate, muted])

  const handleTimeUpdate = useCallback(
    (event: React.SyntheticEvent<HTMLAudioElement, Event>): void => {
      if (onTimeUpdate) onTimeUpdate(event)
      setCurrentTime(innerRef.current?.currentTime || 0)
    },
    [onTimeUpdate]
  )

  const handleSeek = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      if (innerRef.current)
        innerRef.current.currentTime = duration * (Number.parseInt(event.target.value, 10) / 100)
    },
    [duration]
  )

  if (error) return <div css={[errorCss, labelCss]}>{error}</div>

  return (
    <div className={className} css={audioCss} aria-label="Audio Player" role="region">
      <button
        css={buttonCss}
        title={playing ? 'Pause' : 'Play'}
        type="button"
        aria-controls={id}
        onClick={handlePlaybackClick}
      >
        <img
          src={playing ? pauseIcon : whitePlayIcon}
          alt={playing ? 'Pause' : 'Play'}
          css={playIconCss}
        />
      </button>
      <div css={seekerContainerCss}>
        <div css={seekerCss}>
          <input
            type="range"
            min="0"
            max="100"
            value={duration ? Math.floor((currentTime / duration) * 100) : 0}
            onChange={handleSeek}
            aria-label="Seek audio"
          />
        </div>
        <div css={timestampsCss}>
          <span css={[timeCss, blackTextCss]}>{durationToMinutesAndSeconds(currentTime)}</span>
          <span css={[timeCss, blackTextCss]}>{durationToMinutesAndSeconds(duration)}</span>
        </div>
      </div>
      <div css={muteContainerCss}>
        <button
          css={buttonCss}
          title={muted ? 'Unmute' : 'Mute'}
          type="button"
          onClick={handleMuteClick}
        >
          <img
            src={muted ? muteIcon : unmuteIcon}
            alt={muted ? 'Unmute' : 'Mute'}
            css={muteIconCss}
          />
        </button>
        <span css={[muteLabelCss, blackTextCss]}>{muted ? 'Unmute' : 'Mute'}</span>
      </div>
      <audio
        ref={mergedRef}
        css={hiddenAccessibleCss}
        id={id}
        controls
        autoPlay={autoPlay}
        muted={muted}
        onTimeUpdate={handleTimeUpdate}
        {...audioProps}
      >
        {children}
      </audio>
    </div>
  )
}

export default forwardRef(Audio)
