import {css} from '@emotion/react'

import subtractIcon from '../../assets/icons/subtract.svg'
import {WideContent, AnimatedContent} from '../../components/content'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import {
  centeredTextCss,
  h1Css,
  h3SubheadlineCss,
  hBoldCss,
  labelCss,
  whiteTextCss,
} from '../../styles/common'

const VIDEO_IMAGE = 'https://static.kensho.com/home/video/hero-2.jpg'
const VIDEO_MP4 = 'https://static.kensho.com/home/video/hero-2.mp4'
const VIDEO_WEBM_AV1 = 'https://static.kensho.com/home/video/hero-2-av1.webm'
const VIDEO_WEBM_VP9 = 'https://static.kensho.com/home/video/hero-2-vp9.webm'

const heroCss = css`
  position: relative;
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.95) 0%, rgba(0, 0, 0, 0) 41.15%);
`

const videoCss = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  min-width: 100%;
  min-height: 100%;
`

const vignetteCss = css`
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) calc(50% - 930px),
    rgba(0, 0, 0, 0) calc(50% + 930px),
    rgba(0, 0, 0, 1) 100%
  );

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    background: none;
  }
`

const fallbackBackgroundCss = css`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -2;
  background: rgba(0, 0, 0, 0.9);
`

const contentCss = css`
  display: flex;
  justify-content: center;
`

const titleCss = css`
  margin-top: 210px;
  margin-bottom: 70px;
  letter-spacing: 0;
  text-align: left;

  text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14),
    0px 1px 18px rgba(0, 0, 0, 0.12);

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-top: 120px;
    margin-bottom: 80px;
    font-size: 113px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-top: 120px;
    margin-bottom: 30px;
    font-size: 73px;
  }
`

const subtitleCss = css`
  max-width: 900px;
  text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14),
    0px 1px 18px rgba(0, 0, 0, 0.12);

  &:first-of-type {
    padding-top: 150px;
  }

  & + & {
    padding-top: 40px;
  }

  &:last-of-type {
    padding-bottom: 85px;
  }

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    &:first-of-type {
      padding-top: 140px;
    }

    & + & {
      padding-top: 40px;
    }

    &:last-of-type {
      padding-bottom: 8=0px;
    }
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    &:first-of-type {
      padding-top: 55px;
    }

    & + & {
      padding-top: 40px;
    }

    &:last-of-type {
      padding-bottom: 55px;
    }
  }
`

const breakWordCss = css`
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    overflow-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
`

const exploreIconCss = css`
  margin-top: 5px;
`

export default function Hero(): JSX.Element {
  return (
    <div css={heroCss}>
      <div css={vignetteCss}>
        <div css={fallbackBackgroundCss} />
        <AnimatedContent animation="fade-in">
          <video css={videoCss} autoPlay loop muted poster={VIDEO_IMAGE}>
            <source src={VIDEO_WEBM_AV1} type='video/webm; codecs="av01.0.05M.08"' />
            <source src={VIDEO_WEBM_VP9} type='video/webm; codecs="vp9"' />
            <source src={VIDEO_MP4} type="video/mp4" />
            <img src={VIDEO_IMAGE} alt="Mobile Fallback" />
          </video>
        </AnimatedContent>
        <WideContent css={contentCss}>
          <AnimatedContent>
            <h1 css={[h1Css, hBoldCss, whiteTextCss, titleCss]}>
              We <span css={breakWordCss}>illuminate</span> insights in the worldʼs data
            </h1>
          </AnimatedContent>
        </WideContent>
        <WideContent css={centeredTextCss}>
          <AnimatedContent animation="fade-in">
            <h4 css={[labelCss, hBoldCss, whiteTextCss]}>Explore</h4>
            <img css={exploreIconCss} src={subtractIcon} alt="Explore" />
          </AnimatedContent>
        </WideContent>
        <WideContent css={contentCss}>
          <AnimatedContent>
            <h2 css={[h3SubheadlineCss, hBoldCss, whiteTextCss, subtitleCss]}>
              The overwhelming majority of the world’s data has no standardized format.
            </h2>
            <h2 css={[h3SubheadlineCss, hBoldCss, whiteTextCss, subtitleCss]}>
              Without structure, critical insights are hidden, and users are unable to make
              data-driven decisions.
            </h2>
          </AnimatedContent>
        </WideContent>
      </div>
    </div>
  )
}
