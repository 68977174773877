import {css, keyframes} from '@emotion/react'
import {IconCircleCheck, IconCircleX} from '@kensho/icons'
import {useWindowSize} from '@kensho/tacklebox'
import {useState} from 'react'

import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import Select from '../../components/Select'
import {h4Body1Css, h4Body2Css, hBoldCss, blackTextCss} from '../../styles/common'
import {AnimatedContent, WideContent} from '../../components/content'
import COLORS from '../../styles/colors'

interface Example {
  title: string
  example: React.ReactNode
  competition: React.ReactNode
  nerd: React.ReactNode
}

const containerCss = css`
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin-top: 95px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    padding-right: 30px;
    margin-top: 85px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    flex-direction: column;
    margin-top: 40px;
  }
`

const exampleListCss = css`
  list-style-type: none;
  margin: 0 0 -20px 110px;
  padding: 0px 85px 0px 0px;
  flex: 0 0 auto;

  border-right: 1px solid ${COLORS.black};

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    padding-right: 30px;
    width: 255px;
    margin-left: 0px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    border-right-width: 0px;
    border-bottom-width: 2px;
  }
`

const exampleListItemCss = css`
  margin-bottom: 20px;
`

const exampleListItemButtonCss = css`
  display: flex;
  align-items: center;
  text-align: left;
  line-height: 30px;
  padding: 10px;
  font-size: 16px;
  border: none;

  &:hover {
    cursor: pointer;
    background: rgba(80, 175, 198, 0.2);
  }
`

const selectedCss = css`
  background: ${COLORS.brand.light[300]};
  color: ${COLORS.white};
`

const selectCss = css`
  margin: 0 auto;
  width: 100%;
  border: 2px solid ${COLORS.brand.light[700]};
`

const exampleCss = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 85px;
  flex: 1 1 0;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-left: 30px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-left: 0px;
    margin-top: 30px;
  }
`

const rowCss = css`
  display: flex;
  align-items: flex-start;
  margin: 10px 0;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const labelCss = css`
  width: 160px;
  margin-right: 20px;
  white-space: nowrap;
  display: inline-block;
  flex: 0 0 auto;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-right: 0px;
    margin-bottom: 20px;
  }
`

const resultCss = css`
  display: flex;
  align-items: center;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    align-items: flex-end;
  }
`

const exampleTextPositionCss = css`
  text-align: left;
  margin: 0;
  line-height: 38px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    max-width: 240px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    max-width: none;
  }
`

const exampleTextCss = [h4Body2Css, blackTextCss, exampleTextPositionCss]

// highlights and icons have an enter animation with sequenced delays
const HIGHLIGHT_DURATION_SECONDS = 0.6
const SOURCE_DELAY_SECONDS = 0.8
const WRONG_ICON_DELAY_SECONDS = 1.4
const NERD_HIGHLIGHT_DELAY_SECONDS = 2.1
const RIGHT_ICON_DELAY_SECONDS = 2.4

const appearRight = keyframes`
  from, 0% {
    transform: translateX(-50%);
    opacity: 0.00001;
  }

  to, 100% {
    transform: translateX(0);
    opacity: 1;
  }
`

const slideRight = keyframes`
  from, 0% {
    right: 100%;
  }

  to, 100% {
    right: 0;
  }
`

const toWhite = keyframes`
  from, 0% {
    color: #444;
  }

  to, 100% {
    color: ${COLORS.white};
  }
`

const iconCss = css`
  flex: 0 0 auto;
  opacity: 0;
  margin-left: 20px;
`

const wrongIconCss = css`
  animation: ${HIGHLIGHT_DURATION_SECONDS}s ${appearRight} ease ${WRONG_ICON_DELAY_SECONDS}s
    forwards;
  color: #ff0404;
`

const rightIconCss = css`
  animation: ${HIGHLIGHT_DURATION_SECONDS}s ${appearRight} ease ${RIGHT_ICON_DELAY_SECONDS}s
    forwards;
  color: #007d9c;
`

const baseHighlightCss = css`
  display: inline-block;
  padding: 5px;
  line-height: 38px;
  position: relative;
  z-index: 1;
  white-space: nowrap;

  &::after {
    z-index: -1;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 100%;
    bottom: 0;
  }
`

const sourceHighlightCss = css`
  animation: ${HIGHLIGHT_DURATION_SECONDS}s ${toWhite} ease ${SOURCE_DELAY_SECONDS}s forwards;

  &::after {
    animation: ${HIGHLIGHT_DURATION_SECONDS}s ${slideRight} ease ${SOURCE_DELAY_SECONDS}s forwards;

    background: #444;
  }
`

const erroneousHighlightCss = css`
  &::after {
    animation: ${HIGHLIGHT_DURATION_SECONDS}s ${slideRight} ease ${SOURCE_DELAY_SECONDS}s forwards;

    border-bottom: 1px solid #444;
  }
`

const nerdHighlightCss = css`
  animation: ${HIGHLIGHT_DURATION_SECONDS}s ${toWhite} ease ${NERD_HIGHLIGHT_DELAY_SECONDS}s
    forwards;

  &::after {
    animation: ${HIGHLIGHT_DURATION_SECONDS}s ${slideRight} ease ${NERD_HIGHLIGHT_DELAY_SECONDS}s
      forwards;
    background: ${COLORS.brand.light[700]};
  }
`

// have to give key prop to highlight spans because otherwise React will re-use the same components
// and not fire animation when switching between examples
const examplesData: Example[] = [
  {
    title: 'Duplicative Company Names',
    example: (
      <p css={exampleTextCss}>
        <span css={[baseHighlightCss, sourceHighlightCss]}>MorningStar Farms’</span> newest line of
        plant-based foods promises…
      </p>
    ),
    competition: (
      <p css={exampleTextCss}>identifies MorningStar, Inc. (financial services company)</p>
    ),
    nerd: (
      <p css={exampleTextCss}>
        identifies <span css={[baseHighlightCss, nerdHighlightCss]}>MorningStar Farms</span>
      </p>
    ),
  },
  {
    title: 'Picking Up Contextual Clues',
    example: (
      <p css={exampleTextCss}>
        Nairobi denied a request by{' '}
        <span css={[baseHighlightCss, sourceHighlightCss]}>Supreme Court</span> judges…
      </p>
    ),
    competition: <p css={exampleTextCss}>identifies Supreme Court of United States</p>,
    nerd: (
      <p css={exampleTextCss}>
        identifies <span css={[baseHighlightCss, nerdHighlightCss]}>Supreme Court of Kenya</span>
      </p>
    ),
  },
  {
    title: 'Product & Company',
    example: (
      <p css={exampleTextCss}>
        Production of the 2022{' '}
        <span css={[baseHighlightCss, sourceHighlightCss]}>Ford Maverick</span> is well underway…
      </p>
    ),
    competition: <p css={exampleTextCss}>identifies Ford Motor Company</p>,
    nerd: (
      <p css={exampleTextCss}>
        identifies <span css={[baseHighlightCss, nerdHighlightCss]}>Ford Motor Company</span> and{' '}
        <span css={[baseHighlightCss, nerdHighlightCss]}>Maverick</span> compact pickup truck
      </p>
    ),
  },
  {
    title: 'Complex Corporate Hierarchies',
    example: (
      <p css={exampleTextCss}>
        <span css={[baseHighlightCss, sourceHighlightCss]}>RBS Securities Japan</span>, a subsidiary
        of <span css={[baseHighlightCss, sourceHighlightCss]}>The Royal Bank of Scotland</span>,
        announced…
      </p>
    ),
    competition: <p css={exampleTextCss}>identifies The Royal Bank of Scotland</p>,
    nerd: (
      <p css={exampleTextCss}>
        identifies{' '}
        <span css={[baseHighlightCss, nerdHighlightCss]}>
          RBS Securities Japan Limited (NatWest subsidiary)
        </span>{' '}
        and identifies{' '}
        <span css={[baseHighlightCss, nerdHighlightCss]}>
          NatWest Group PLC (name change, July 2020)
        </span>
      </p>
    ),
  },
  {
    title: 'Common Names',
    example: (
      <p css={exampleTextCss}>
        <span css={[baseHighlightCss, sourceHighlightCss]}>David Simon</span> plans to expand Simon
        Property Group’s retail operations…
      </p>
    ),
    competition: <p css={exampleTextCss}>identifies David Simon, the author/producer</p>,
    nerd: (
      <p css={exampleTextCss}>
        identifies David Simon, the{' '}
        <span css={[baseHighlightCss, nerdHighlightCss]}>CEO of Simon Property Group </span>
      </p>
    ),
  },
  {
    title: 'Erroneous Annotation',
    example: (
      <p css={exampleTextCss}>
        <span css={[baseHighlightCss, sourceHighlightCss]}>Boris Johnson</span> visited the{' '}
        <span css={[baseHighlightCss, erroneousHighlightCss]}>area</span>
        Tuesday…
      </p>
    ),
    competition: (
      <p css={exampleTextCss}>identifies Boris and identifies “area“ to an unknown location</p>
    ),
    nerd: (
      <p css={exampleTextCss}>
        identifies{' '}
        <span css={[baseHighlightCss, nerdHighlightCss]}>Prime Minister Boris Johnson</span>
      </p>
    ),
  },
]

export default function Comparison(): JSX.Element {
  const [selected, setSelected] = useState<Example>(examplesData[0])
  const {width} = useWindowSize()

  return (
    <WideContent css={containerCss}>
      {width > BREAKPOINT_SMALL ? (
        <AnimatedContent animation="fade-in">
          <ul css={exampleListCss}>
            {examplesData.map((example, i) => (
              <li key={example.title} css={exampleListItemCss}>
                <button
                  css={[exampleListItemButtonCss, example.title === selected.title && selectedCss]}
                  type="button"
                  onClick={() => setSelected(examplesData[i])}
                >
                  {example.title}
                </button>
              </li>
            ))}
          </ul>
        </AnimatedContent>
      ) : (
        <AnimatedContent animation="fade-in">
          <Select
            css={selectCss}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
              setSelected(
                examplesData.find((example) => example.title === event.target.value) ||
                  examplesData[0]
              )
            }}
          >
            {examplesData.map((example) => (
              <option key={example.title} value={example.title}>
                {example.title}
              </option>
            ))}
          </Select>
        </AnimatedContent>
      )}

      {/* specifying key here so that React won't reuse elements which rely on enter animations */}
      <AnimatedContent animation="fade-in" key={selected.title} css={exampleCss}>
        <div css={rowCss}>
          <span css={[labelCss, h4Body1Css, hBoldCss, blackTextCss]}>Input Text</span>
          {selected.example}
        </div>
        <div css={rowCss}>
          <span css={[labelCss, h4Body1Css, hBoldCss, blackTextCss]}>Competition</span>
          <div css={resultCss}>
            {selected.competition}
            <IconCircleX key={selected.title} css={[iconCss, wrongIconCss]} size={30} />
          </div>
        </div>
        <div css={rowCss}>
          <span css={[labelCss, h4Body1Css, hBoldCss, blackTextCss]}>Kensho NERD</span>
          <div css={resultCss}>
            {selected.nerd}
            <IconCircleCheck key={selected.title} css={[iconCss, rightIconCss]} size={36} />
          </div>
        </div>
      </AnimatedContent>
    </WideContent>
  )
}
