import {css} from '@emotion/react'
import {useElementWidth, useInterval} from '@kensho/tacklebox'
import {useCallback, useEffect, useMemo, useState} from 'react'

import {BREAKPOINT_SMALL} from '../styles/breakpoints'
import COLORS from '../styles/colors'
import {hBoldCss} from '../styles/common'

interface DurationProps {
  initialCount?: number
  rate?: number // accepts negatives
  interval?: number
  className?: string
  animate?: boolean
  animateDuration?: number
}

function easeOutSine(x: number): number {
  return Math.sin((x * Math.PI) / 2)
}

function formatNumberWithCommas(x: number): string[] {
  return x
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    .split('')
}

const counterCss = css`
  display: flex;
  justify-content: center;
  width: 100%;
`

const digitCss = css`
  line-height: 73px;
  font-size: 100px;
  margin: 0;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    font-size: 50px;
  }
`

const numberCss = css`
  display: flex;
  width: 58px;

  p {
    width: 58px;
    text-align: center;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: 30px;

    p {
      width: 30px;
    }
  }
`

const commaCss = css`
  width: 45px;

  p {
    text-align: left;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: 25px;

    p {
      width: 25px;
    }
  }
`

const blueGreenTextCss = css`
  color: ${COLORS.brand.light[300]};
`

/** Counts time counter at the specified interval and rate. Matches real time by default. */
export default function Counter({
  className,
  initialCount = 0,
  rate = 1,
  interval = 1000,
  animate = false,
  animateDuration = 1500,
}: DurationProps): JSX.Element {
  const [counter, setCounter] = useState(animate ? 0 : initialCount)
  const [enterAnimationCompleted, setEnterAnimationCompleted] = useState(!animate)

  useEffect(() => {
    let animateInterval: number
    if (animate && !enterAnimationCompleted) {
      const startMs = Date.now()
      animateInterval = window.setInterval(() => {
        const elapsedMs = Date.now() - startMs
        if (elapsedMs >= animateDuration) setEnterAnimationCompleted(true)
        setCounter(
          Math.floor(
            initialCount * easeOutSine(Math.min(elapsedMs, animateDuration) / animateDuration)
          )
        )
      }, 1000 / 30)
    }

    return () => {
      window.clearInterval(animateInterval)
    }
  }, [animate, animateDuration, initialCount, enterAnimationCompleted])

  const formattedNumbers = useMemo(() => formatNumberWithCommas(Math.round(counter)), [counter])
  const increment = useCallback(() => {
    if (enterAnimationCompleted)
      setCounter((prevCounter) => Math.max(prevCounter + rate * interval, 0))
  }, [rate, interval, enterAnimationCompleted])
  useInterval(increment, interval)
  const [, widthRef] = useElementWidth()

  return (
    <div ref={widthRef} css={counterCss} className={className}>
      {formattedNumbers.map((digit, i) => (
        /* eslint-disable-next-line react/no-array-index-key */
        <div css={[numberCss, digit === ',' && commaCss]} key={i}>
          <p css={[digitCss, hBoldCss, blueGreenTextCss]}>{digit}</p>
        </div>
      ))}
    </div>
  )
}
