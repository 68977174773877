import {useEffect, useMemo, useState} from 'react'
import {css} from '@emotion/react'
import {useLogger} from '@kensho/lumberjack'
import {IconChevronRightLarge} from '@kensho/icons'
import {uniqBy} from 'lodash'

import {LeverJob} from '../pages/Careers/Category'
import {groupBy} from '../utils'
import ensoImg from '../assets/logos/enso4.png'
import {
  centeredTextCss,
  h2TitleCss,
  h3TitleCss,
  h4Body1Css,
  h4Body2Css,
  hBoldCss,
} from '../styles/common'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../styles/breakpoints'

import ButtonLink from './Button/ButtonLink'
import TopicHeadline from './TopicHeadline'

const sectionCss = css`
  height: 100%;
  width: 100%;
  padding-top: 65px;
  padding-bottom: 55px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding-left: 0;
    padding-right: 0;
  }
`

const marginCss = css`
  margin-bottom: 10px;
`

const jobsGridCss = css`
  margin-top: 100px;
  margin-bottom: 70px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-top: 80px;
    margin-bottom: 80px;
  }
`

const jobsRowCss = css`
  display: flex;
  justify-content: space-evenly;

  margin-top: 65px;

  &:first-of-type {
    margin-top: 0;
  }

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    margin-top: 50px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
`

const jobCss = css`
  width: 160px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    & + & {
      margin-top: 50px;
    }
  }
`

const openPositionsCountCss = css`
  line-height: 100px;
`

const callToActionCss = css`
  display: flex;
  justify-content: center;
`

const buttonLinkCss = css`
  padding: 20px 60px;
`

const JOBS_URL = 'https://api.lever.co/v0/postings/kensho?mode=json'

export default function HiringPreviewSection(): JSX.Element {
  const log = useLogger()
  const [jobs, setJobs] = useState<LeverJob[] | null>(null)
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    fetch(JOBS_URL)
      .then((response) => response.json())
      .then(setJobs)
      .catch((error) => {
        setHasError(true)
        log.error(error)
      })
  }, [log])

  const entriesByDepartment = useMemo(
    () =>
      Object.entries(
        groupBy(
          uniqBy(jobs, (job) => job.text),
          (job) => job.categories.department
        )
      ),
    [jobs]
  )
  const isLoadingJobs = jobs === null && !hasError
  const firstRowLength = Math.ceil(entriesByDepartment.length / 2)
  const rows =
    entriesByDepartment.length > 2
      ? [entriesByDepartment.slice(0, firstRowLength), entriesByDepartment.slice(firstRowLength)]
      : [entriesByDepartment]

  return (
    <section css={sectionCss}>
      <TopicHeadline text="Careers" />
      <h3 css={[h3TitleCss, hBoldCss, marginCss]}>Our team is growing!</h3>
      <p css={h4Body1Css}>Positions currently open:</p>
      <div css={jobsGridCss}>
        {isLoadingJobs ? (
          <img src={ensoImg} alt="Loading open positions..." />
        ) : (
          rows.map((rowEntries) => (
            <div key={rowEntries[0][0]} css={jobsRowCss}>
              {rowEntries.map(([departmentKey, openPositions]) => (
                <div css={[jobCss, centeredTextCss]} key={departmentKey}>
                  <h3 css={[h2TitleCss, openPositionsCountCss]}>{openPositions.length}</h3>
                  <h3 css={h4Body2Css}>{departmentKey}</h3>
                </div>
              ))}
            </div>
          ))
        )}
      </div>
      <div css={callToActionCss}>
        <ButtonLink
          to="/careers#available-positions"
          variant="primary"
          mode="light"
          css={[buttonLinkCss, h4Body1Css]}
        >
          All opportunities
          <IconChevronRightLarge size={20} />
        </ButtonLink>
      </div>
    </section>
  )
}
