import {useState, useEffect, useMemo} from 'react'
import {useLogger} from '@kensho/lumberjack'
import {css} from '@emotion/react'

import {groupBy} from '../../utils'
import {centeredTextCss} from '../../styles/common'

import Category, {LeverJob} from './Category'

const JOBS_URL = 'https://api.lever.co/v0/postings/kensho?mode=json'

const allJobsCss = css`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-around;
  margin-bottom: -32px;
`

const filtersCss = css`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 5px auto 10px auto;
`

const filterCss = css`
  width: 200px;
  height: 32px;
  margin: 10px;
`

export default function AvailablePositions(): JSX.Element {
  const log = useLogger()
  const [department, setDepartment] = useState<string>('')
  const [jobLocation, setJobLocation] = useState<string>('')
  const [jobs, setJobs] = useState<LeverJob[] | null>(null)
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    fetch(JOBS_URL)
      .then((response) => response.json())
      .then(setJobs)
      .catch((error) => {
        setHasError(true)
        log.error(error)
      })
  }, [log])

  const categories = useMemo(() => {
    const jobCategories = {
      department: new Set<string>(),
      location: new Set<string>(),
    }

    if (!jobs) return jobCategories

    jobs.forEach((job) => {
      jobCategories.department.add(job.categories.department)
      jobCategories.location.add(job.categories.location)
      job.categories.allLocations.forEach((location) => {
        jobCategories.location.add(location)
      })
    })

    return jobCategories
  }, [jobs])

  const filteredJobs = useMemo(
    () =>
      (jobs || []).filter(
        (job) =>
          (!department || department === job.categories.department) &&
          (!jobLocation ||
            jobLocation === job.categories.location ||
            job.categories.allLocations.includes(jobLocation))
      ),
    [jobs, department, jobLocation]
  )
  const byDepartment = useMemo(
    () => groupBy(filteredJobs, (job) => job.categories.department),
    [filteredJobs]
  )
  const isLoadingJobs = jobs === null && !hasError

  return (
    <>
      {jobs?.length && (
        <>
          <div css={filtersCss}>
            <select
              css={filterCss}
              value={department}
              onChange={(event) => setDepartment(event.target.value)}
            >
              {/* Lever job posting has a categories property, but
                "All Categories" sounds friendlier/more neutral than "All Departments" */}
              <option value="">All Categories</option>
              {[...categories.department].sort().map((d) => (
                <option key={d} value={d}>
                  {d}
                </option>
              ))}
            </select>
            <select
              css={filterCss}
              value={jobLocation}
              onChange={(event) => setJobLocation(event.target.value)}
            >
              <option value="">All Locations</option>
              {[...categories.location].sort().map((l) => (
                <option key={l} value={l}>
                  {l}
                </option>
              ))}
            </select>
          </div>

          <div css={[allJobsCss, centeredTextCss]}>
            {Object.keys(byDepartment)
              .sort()
              .map((name) => ({name, jobs: byDepartment[name]}))
              .filter((category) => category.jobs.length)
              .map((category) => (
                <Category {...category} key={category.name} />
              ))}
          </div>
        </>
      )}

      {isLoadingJobs && <p>Loading available positions…</p>}
      {hasError && <p>Unable to load available positions. Please check back later.</p>}
      {Array.isArray(jobs) && !jobs.length && (
        <p>There are no available positions at this time. Please check back later.</p>
      )}
    </>
  )
}
