import {css, SerializedStyles, keyframes} from '@emotion/react'

import {h4Body1Css, hBoldCss, blackTextCss} from '../../../styles/common'
import {BREAKPOINT_MEDIUM, BREAKPOINT_SMALL} from '../../../styles/breakpoints'

const sampleCss = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
`

const paragraphContainerCss = css`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-bottom: 30px;
  }
`

const titleCss = css`
  margin-bottom: 25px;
  font-size: 32px;
  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    font-size: 24px;
  }
`

const paragraphTitleCss = css`
  margin-bottom: 10px;
  font-size: 24px;
  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    font-size: 18px;
  }
`

const paragraphAndTopicsCss = css`
  display: flex;
  max-width: 1200px;
  width: 100%;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    flex-direction: column;
  }
`

const paragraphTextCss = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 0;
`

const grayHightlightCss = css`
  fill: #e5e4e2;
`

const colorBlockContainerCss = css`
  display: flex;
  width: 46px;
  margin: 0 20px;

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    width: 31px;
    margin: 0 12px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: 100%;
    margin: 10px 0 0;
    flex: 1;
  }
`

const edgeBlockCss = css`
  width: 10px;

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    width: 7px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: unset;
    flex: 1;
    max-width: 33.3%;
    height: 10px;
  }
`

const middleBlockCss = css`
  width: 10px;
  margin: 0 8px;

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    width: 7px;
    margin: 0 5px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: unset;
    flex: 1;
    max-width: 33.3%;
    height: 10px;
  }
`

const blueCss = css`
  background-color: #0097a7;
`

const blueTextCss = css`
  color: #0097a7;
`

const redCss = css`
  background-color: #e06666;
`

const redTextCss = css`
  color: #e06666;
`

const purpleCss = css`
  background-color: #674ea7;
`

const purpleTextCss = css`
  color: #674ea7;
`

const topicsListCss = css`
  width: 200px;
  display: flex;
  flex-direction: column;
  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    width: 140px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: 100%;
    flex-direction: row;
  }
`

const topicsEndJustifyCss = css`
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    justify-content: flex-end;
  }
`

const topicsGapJustifyCss = css`
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    justify-content: space-between;
  }
`

const topicsTextCss = css`
  font-size: 24px;
  line-height: 30px;
  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    font-size: 18px;
    line-height: 24px;
  }
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    font-size: 14px;
    line-height: 20px;
    flex: 1;
    max-width: 33.3%;
    text-align: center;
  }
`

const topicsTitleCss = css`
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    display: none;
  }
`

const LINE_HIGHLIGHT_SECONDS = 0.5
const SAMPLE_FADE_SECONDS = 1
const TOPICS_FADE_SECONDS = 0.5

// Animates a initial delay, highlights a line according to its width,
// and then wait period while the rest of animation continues before repeating
const highlightKeyframes = (width: number, delay: number, postWait: number): string => keyframes`
  from,
  ${(delay * 100) / (delay + LINE_HIGHLIGHT_SECONDS * width * 0.01 + postWait)}% {
    width: 0%;
  }
  ${
    ((delay + LINE_HIGHLIGHT_SECONDS * width * 0.01) * 100) /
    (delay + LINE_HIGHLIGHT_SECONDS * width * 0.01 + postWait)
  }%, 
  to {
    width: ${width}%;
  }
`

function highlightAnimation(width: number, delay: number, postWait: number): SerializedStyles {
  return css`
    animation: ${highlightKeyframes(width, delay, postWait)}
      ${delay + LINE_HIGHLIGHT_SECONDS * width * 0.01 + postWait}s linear infinite;
  `
}

// Animates a initial delay, fade-in for supplied number of seconds, visible for given duration,
// fadeout, and then wait period while the rest of animation continues before repeating
const sampleFadeKeyframes = (
  duration: number,
  delay: number,
  postWait: number
): string => keyframes`
  from,
  ${(delay * 100) / (delay + duration + postWait + 2 * SAMPLE_FADE_SECONDS)}%,
  ${
    ((delay + duration + 2 * SAMPLE_FADE_SECONDS) * 100) /
    (delay + duration + postWait + 2 * SAMPLE_FADE_SECONDS)
  }%, 
  to {
    opacity: 0;
  }
  ${
    ((delay + 1 * SAMPLE_FADE_SECONDS) * 100) /
    (delay + duration + postWait + 2 * SAMPLE_FADE_SECONDS)
  }%,
  ${
    ((delay + duration + 1 * SAMPLE_FADE_SECONDS) * 100) /
    (delay + duration + postWait + 2 * SAMPLE_FADE_SECONDS)
  }% {
    opacity: 1;
  }
`

function sampleFadeAnimation(duration: number, delay: number, postWait: number): SerializedStyles {
  return css`
    animation: ${sampleFadeKeyframes(duration, delay, postWait)}
      ${delay + duration + postWait + 2 * SAMPLE_FADE_SECONDS}s infinite;
  `
}

// Animates a initial delay, fade-in for supplied number of seconds,
// and then wait period for rest of animation
const topicsFadeKeyframes = (delay: number, postWait: number): string => keyframes`
  from,
  ${(delay * 100) / (delay + postWait + TOPICS_FADE_SECONDS)}% {
    opacity: 0;
  }
  ${((delay + TOPICS_FADE_SECONDS) * 100) / (delay + postWait + TOPICS_FADE_SECONDS)}%,
  to {
    opacity: 1;
  }
`

function topicsFadeAnimation(delay: number, postWait: number): SerializedStyles {
  return css`
    animation: ${topicsFadeKeyframes(delay, postWait)} ${delay + postWait + TOPICS_FADE_SECONDS}s
      infinite;
  `
}

interface AnimatedSample {
  title: string
  content: React.ReactNode
}

const classifySamples: AnimatedSample[] = [
  {
    title: 'Call Transcripts',
    content: (
      <div css={[sampleCss, sampleFadeAnimation(7.675, 1.5, 26.1)]}>
        {' '}
        {/* 1.5s delay from load to fadein start */}
        <h3 css={[titleCss, hBoldCss, blackTextCss]}>Call Transcripts</h3>
        <div css={paragraphContainerCss}>
          <p css={[paragraphTitleCss, hBoldCss, blackTextCss]}>Brent John Thill</p>
          <div css={paragraphAndTopicsCss}>
            <div css={paragraphTextCss}>
              <svg width="100%" height="auto" viewBox="0 0 930 37">
                <rect
                  x="0"
                  y="12.5%"
                  width="86.5%"
                  height="75%"
                  css={[grayHightlightCss, highlightAnimation(86.5, 2.5, 34.3475)]}
                />{' '}
                {/* highlight begins immediately after fadein */}
                <text x="0" y="50%" css={[h4Body1Css, blackTextCss]} dominantBaseline="central">
                  On Google Cloud, we continue to hear from the partners that the deal size is
                </text>
              </svg>
              <svg width="100%" height="auto" viewBox="0 0 930 37">
                <rect
                  x="0"
                  y="12.5%"
                  width="91%"
                  height="75%"
                  css={[grayHightlightCss, highlightAnimation(91, 2.9325, 33.8875)]}
                />
                <text x="0" y="50%" css={[h4Body1Css, blackTextCss]} dominantBaseline="central">
                  building and BigQuery’s having a big impact for a lot of your customers. Can you
                </text>
              </svg>
              <svg width="100%" height="auto" viewBox="0 0 930 37">
                <rect
                  x="0"
                  y="12.5%"
                  width="87%"
                  height="75%"
                  css={[grayHightlightCss, highlightAnimation(87, 3.3875, 33.4525)]}
                />
                <text x="0" y="50%" css={[h4Body1Css, blackTextCss]} dominantBaseline="central">
                  just talk to and expand on what you’re seeing there and any other noticeable
                </text>
              </svg>
              <svg width="100%" height="auto" viewBox="0 0 930 37">
                <rect
                  x="0"
                  y="12.5%"
                  width="80%"
                  height="75%"
                  css={[grayHightlightCss, highlightAnimation(80, 3.8225, 33.0525)]}
                />
                <text x="0" y="50%" css={[h4Body1Css, blackTextCss]} dominantBaseline="central">
                  trends that you’re seeing now that maybe you hadn’t seen in the past?
                </text>
              </svg>
            </div>
            <div css={[colorBlockContainerCss, topicsFadeAnimation(4.7225, 32.0525)]}>
              {' '}
              {/* 0.5s delay from text highlight finish to topics fadein */}
              <div css={[edgeBlockCss, blueCss]} />
              <div css={middleBlockCss} />
              <div css={edgeBlockCss} />
            </div>
            <div css={[topicsListCss, topicsFadeAnimation(4.7225, 32.0525)]}>
              <p css={[topicsTitleCss, topicsTextCss, hBoldCss, blackTextCss]}>Topics:</p>
              <p css={[topicsTextCss, blueTextCss]}>Cloud Computing</p>
            </div>
          </div>
        </div>
        <div css={paragraphContainerCss}>
          <p css={[paragraphTitleCss, hBoldCss, blackTextCss]}>Sundar Pichai</p>
          <div css={paragraphAndTopicsCss}>
            <div css={paragraphTextCss}>
              <svg width="100%" height="auto" viewBox="0 0 930 37">
                <rect
                  x="0"
                  y="12.5%"
                  width="94.5%"
                  height="75%"
                  css={[grayHightlightCss, highlightAnimation(94.5, 5.7225, 31.08)]}
                />{' '}
                {/* 0.5s delay from topic fadein end to new highlighting */}
                <text x="0" y="50%" css={[h4Body1Css, blackTextCss]} dominantBaseline="central">
                  Thanks. Look, overall, we continue to see strong momentum. The team is executing
                </text>
              </svg>
              <svg width="100%" height="auto" viewBox="0 0 930 37">
                <rect
                  x="0"
                  y="12.5%"
                  width="92%"
                  height="75%"
                  css={[grayHightlightCss, highlightAnimation(92, 6.195, 30.62)]}
                />
                <text x="0" y="50%" css={[h4Body1Css, blackTextCss]} dominantBaseline="central">
                  well. You mentioned BigQuery data and analytics and AI continues to be kind of a
                </text>
              </svg>
              <svg width="100%" height="auto" viewBox="0 0 930 37">
                <rect
                  x="0"
                  y="12.5%"
                  width="87.5%"
                  height="75%"
                  css={[grayHightlightCss, highlightAnimation(87.5, 6.655, 30.1825)]}
                />
                <text x="0" y="50%" css={[h4Body1Css, blackTextCss]} dominantBaseline="central">
                  foundational shift for what companies are trying to accomplish, and BigQuery
                </text>
              </svg>
              <svg width="100%" height="auto" viewBox="0 0 930 37">
                <rect
                  x="0"
                  y="12.5%"
                  width="95%"
                  height="75%"
                  css={[grayHightlightCss, highlightAnimation(95, 7.0925, 29.7075)]}
                />
                <text x="0" y="50%" css={[h4Body1Css, blackTextCss]} dominantBaseline="central">
                  does stand out there and we are definitely seeing continued momentum there. It’s a
                </text>
              </svg>
              <svg width="100%" height="auto" viewBox="0 0 930 37">
                <rect
                  x="0"
                  y="12.5%"
                  width="21.5%"
                  height="75%"
                  css={[grayHightlightCss, highlightAnimation(21.5, 7.5675, 29.6)]}
                />
                <text x="0" y="50%" css={[h4Body1Css, blackTextCss]} dominantBaseline="central">
                  source of strength.
                </text>
              </svg>
            </div>
            <div css={[colorBlockContainerCss, topicsFadeAnimation(8.175, 28.6)]}>
              <div css={[edgeBlockCss, blueCss]} />
              <div css={[middleBlockCss, redCss]} />
              <div css={edgeBlockCss} />
            </div>
            <div css={[topicsListCss, topicsFadeAnimation(8.175, 28.6)]}>
              {' '}
              {/* 1.5s remain on screen before fade out begins */}
              <p css={[topicsTitleCss, topicsTextCss, hBoldCss, blackTextCss]}>Topics:</p>
              <p css={[topicsTextCss, blueTextCss]}>Cloud Computing</p>
              <p css={[topicsTextCss, redTextCss]}>Data and Analytics</p>
            </div>
          </div>
        </div>
      </div>
    ),
  },
  {
    title: 'Market Research',
    content: (
      <div css={[sampleCss, sampleFadeAnimation(4.42, 11.675, 19.18)]}>
        {' '}
        {/* 1s fadeout for previous sample, 0.5s break before new sample fadein */}
        <h3 css={[titleCss, hBoldCss, blackTextCss]}>Market Research</h3>
        <div css={paragraphContainerCss}>
          <div css={paragraphAndTopicsCss}>
            <div css={paragraphTextCss}>
              <svg width="100%" height="auto" viewBox="0 0 930 37">
                <rect
                  x="0"
                  y="12.5%"
                  width="98.5%"
                  height="75%"
                  css={[grayHightlightCss, highlightAnimation(98.5, 12.675, 24.1075)]}
                />
                <text x="0" y="50%" css={[h4Body1Css, blackTextCss]} dominantBaseline="central">
                  China’s October NBS PMI report came in below expectations. All indications are
                  that an
                </text>
              </svg>
              <svg width="100%" height="auto" viewBox="0 0 930 37">
                <rect
                  x="0"
                  y="12.5%"
                  width="90%"
                  height="75%"
                  css={[grayHightlightCss, highlightAnimation(90, 13.1675, 23.6575)]}
                />
                <text x="0" y="50%" css={[h4Body1Css, blackTextCss]} dominantBaseline="central">
                  uncertain export market and diminished domestic demand are slowing Chinese
                </text>
              </svg>
              <svg width="100%" height="auto" viewBox="0 0 930 37">
                <rect
                  x="0"
                  y="12.5%"
                  width="93%"
                  height="75%"
                  css={[grayHightlightCss, highlightAnimation(93, 13.6175, 23.1925)]}
                />
                <text x="0" y="50%" css={[h4Body1Css, blackTextCss]} dominantBaseline="central">
                  manufacturing. Ongoing Coronavirus restriction measures and a surge of Omicron
                </text>
              </svg>
              <svg width="100%" height="auto" viewBox="0 0 930 37">
                <rect
                  x="0"
                  y="12.5%"
                  width="90.5%"
                  height="75%"
                  css={[grayHightlightCss, highlightAnimation(90.5, 14.0825, 22.74)]}
                />
                <text x="0" y="50%" css={[h4Body1Css, blackTextCss]} dominantBaseline="central">
                  cases are expected to continue limiting sector activity, consumption, and overall
                </text>
              </svg>
              <svg width="100%" height="auto" viewBox="0 0 930 37">
                <rect
                  x="0"
                  y="12.5%"
                  width="12%"
                  height="75%"
                  css={[grayHightlightCss, highlightAnimation(12, 14.535, 22.68)]}
                />
                <text x="0" y="50%" css={[h4Body1Css, blackTextCss]} dominantBaseline="central">
                  sentiment.
                </text>
              </svg>
            </div>
            <div css={[colorBlockContainerCss, topicsFadeAnimation(15.095, 21.68)]}>
              <div css={[edgeBlockCss, blueCss]} />
              <div css={[middleBlockCss, redCss]} />
              <div css={[edgeBlockCss, purpleCss]} />
            </div>
            <div css={[topicsListCss, topicsFadeAnimation(15.095, 21.68)]}>
              <p css={[topicsTitleCss, topicsTextCss, hBoldCss, blackTextCss]}>Topics:</p>
              <p css={[topicsTextCss, blueTextCss]}>China</p>
              <p css={[topicsTextCss, redTextCss]}>Covid-19</p>
              <p css={[topicsTextCss, purpleTextCss]}>Manufacturing</p>
            </div>
          </div>
        </div>
      </div>
    ),
  },
  {
    title: 'Financial Filings',
    content: (
      <div css={[sampleCss, sampleFadeAnimation(5.3025, 18.595, 11.3775)]}>
        <h3 css={[titleCss, hBoldCss, blackTextCss]}>Financial Filings</h3>
        <div css={paragraphContainerCss}>
          <div css={paragraphAndTopicsCss}>
            <div css={paragraphTextCss}>
              <svg width="100%" height="auto" viewBox="0 0 930 37">
                <rect
                  x="0"
                  y="12.5%"
                  width="99%"
                  height="75%"
                  css={[grayHightlightCss, highlightAnimation(99, 19.595, 17.185)]}
                />
                <text x="0" y="50%" css={[h4Body1Css, blackTextCss]} dominantBaseline="central">
                  We have built a global cloud platform that delivers a broad range of network
                  services to
                </text>
              </svg>
              <svg width="100%" height="auto" viewBox="0 0 930 37">
                <rect
                  x="0"
                  y="12.5%"
                  width="94.5%"
                  height="75%"
                  css={[grayHightlightCss, highlightAnimation(94.5, 20.09, 16.7125)]}
                />
                <text x="0" y="50%" css={[h4Body1Css, blackTextCss]} dominantBaseline="central">
                  businesses of all sizes around the world—making them more secure, enhancing the
                </text>
              </svg>
              <svg width="100%" height="auto" viewBox="0 0 930 37">
                <rect
                  x="0"
                  y="12.5%"
                  width="89.5%"
                  height="75%"
                  css={[grayHightlightCss, highlightAnimation(89.5, 20.5625, 16.265)]}
                />
                <text x="0" y="50%" css={[h4Body1Css, blackTextCss]} dominantBaseline="central">
                  performance of their business-critical applications, and eliminating the cost and
                </text>
              </svg>
              <svg width="100%" height="auto" viewBox="0 0 930 37">
                <rect
                  x="0"
                  y="12.5%"
                  width="92%"
                  height="75%"
                  css={[grayHightlightCss, highlightAnimation(92, 21.01, 15.805)]}
                />
                <text x="0" y="50%" css={[h4Body1Css, blackTextCss]} dominantBaseline="central">
                  complexity of managing and integrating individual network hardware. We provide
                </text>
              </svg>
              <svg width="100%" height="auto" viewBox="0 0 930 37">
                <rect
                  x="0"
                  y="12.5%"
                  width="85%"
                  height="75%"
                  css={[grayHightlightCss, highlightAnimation(85, 21.47, 15.38)]}
                />
                <text x="0" y="50%" css={[h4Body1Css, blackTextCss]} dominantBaseline="central">
                  businesses a scalable, easy-to-use, unified control plane to deliver security,
                </text>
              </svg>
              <svg width="100%" height="auto" viewBox="0 0 930 37">
                <rect
                  x="0"
                  y="12.5%"
                  width="86.5%"
                  height="75%"
                  css={[grayHightlightCss, highlightAnimation(86.5, 21.895, 14.9475)]}
                />
                <text x="0" y="50%" css={[h4Body1Css, blackTextCss]} dominantBaseline="central">
                  performance, and reliability across their on-premise, hybrid, cloud, and SaaS
                </text>
              </svg>
              <svg width="100%" height="auto" viewBox="0 0 930 37">
                <rect
                  x="0"
                  y="12.5%"
                  width="14%"
                  height="75%"
                  css={[grayHightlightCss, highlightAnimation(14, 22.3275, 14.8775)]}
                />
                <text x="0" y="50%" css={[h4Body1Css, blackTextCss]} dominantBaseline="central">
                  applications
                </text>
              </svg>
            </div>
            <div css={[colorBlockContainerCss, topicsFadeAnimation(22.8975, 13.8775)]}>
              <div css={[edgeBlockCss, blueCss]} />
              <div css={[middleBlockCss, redCss]} />
              <div css={[edgeBlockCss, purpleCss]} />
            </div>
            <div css={[topicsListCss, topicsFadeAnimation(22.8975, 13.8775)]}>
              <p css={[topicsTitleCss, topicsTextCss, hBoldCss, blackTextCss]}>Topics:</p>
              <p css={[topicsTextCss, blueTextCss]}>Cloud Computing</p>
              <p css={[topicsTextCss, redTextCss]}>Software</p>
              <p css={[topicsTextCss, purpleTextCss]}>Enterprise Applications</p>
            </div>
          </div>
        </div>
      </div>
    ),
  },
  {
    title: 'News Articles',
    content: (
      <div css={[sampleCss, sampleFadeAnimation(8.8775, 26.3975, 0)]}>
        <h3 css={[titleCss, hBoldCss, blackTextCss]}>News Articles</h3>
        <div css={paragraphContainerCss}>
          <div css={paragraphAndTopicsCss}>
            <div css={paragraphTextCss}>
              <svg width="100%" height="auto" viewBox="0 0 930 37">
                <rect
                  x="0"
                  y="12.5%"
                  width="99%"
                  height="75%"
                  css={[grayHightlightCss, highlightAnimation(99, 27.3975, 9.3825)]}
                />
                <text x="0" y="50%" css={[h4Body1Css, blackTextCss]} dominantBaseline="central">
                  Federal Reserve Chair Jerome Powell stated that the central bank expects to raise
                  rates
                </text>
              </svg>
              <svg width="100%" height="auto" viewBox="0 0 930 37">
                <rect
                  x="0"
                  y="12.5%"
                  width="96%"
                  height="75%"
                  css={[grayHightlightCss, highlightAnimation(96, 27.8925, 8.9025)]}
                />
                <text x="0" y="50%" css={[h4Body1Css, blackTextCss]} dominantBaseline="central">
                  by a half percentage point at its next meeting as part of its ongoing fight
                  against high
                </text>
              </svg>
              <svg width="100%" height="auto" viewBox="0 0 930 37">
                <rect
                  x="0"
                  y="12.5%"
                  width="10%"
                  height="75%"
                  css={[grayHightlightCss, highlightAnimation(10, 28.3725, 8.8525)]}
                />
                <text x="0" y="50%" css={[h4Body1Css, blackTextCss]} dominantBaseline="central">
                  inflation.
                </text>
              </svg>
            </div>
            <div css={[colorBlockContainerCss, topicsFadeAnimation(28.9225, 7.8525)]}>
              <div css={[edgeBlockCss, blueCss]} />
              <div css={[middleBlockCss, redCss]} />
              <div css={[edgeBlockCss, purpleCss]} />
            </div>
            <div css={[topicsListCss, topicsFadeAnimation(28.9225, 7.8525)]}>
              <p css={[topicsTitleCss, topicsTextCss, hBoldCss, blackTextCss]}>Topics:</p>
              <p css={[topicsTextCss, blueTextCss]}>Interest Rates</p>
              <p css={[topicsTextCss, redTextCss]}>Inflation</p>
              <p css={[topicsTextCss, purpleTextCss]}>Federal Reserve</p>
            </div>
          </div>
        </div>
        <div css={paragraphContainerCss}>
          <div css={paragraphAndTopicsCss}>
            <div css={paragraphTextCss}>
              <svg width="100%" height="auto" viewBox="0 0 930 37">
                <rect
                  x="0"
                  y="12.5%"
                  width="98%"
                  height="75%"
                  css={[grayHightlightCss, highlightAnimation(98, 29.9225, 6.8625)]}
                />
                <text x="0" y="50%" css={[h4Body1Css, blackTextCss]} dominantBaseline="central">
                  Mr. Powell pointed to the labor market as “key” to fighting inflation, saying that
                  that the
                </text>
              </svg>
              <svg width="100%" height="auto" viewBox="0 0 930 37">
                <rect
                  x="0"
                  y="12.5%"
                  width="78%"
                  height="75%"
                  css={[grayHightlightCss, highlightAnimation(78, 30.4125, 6.4725)]}
                />
                <text x="0" y="50%" css={[h4Body1Css, blackTextCss]} dominantBaseline="central">
                  labor market must cool to meet the central bank’s 2% inflation target.
                </text>
              </svg>
            </div>
            <div css={[colorBlockContainerCss, topicsFadeAnimation(31.3025, 5.4725)]}>
              <div css={edgeBlockCss} />
              <div css={[middleBlockCss, redCss]} />
              <div css={[edgeBlockCss, purpleCss]} />
            </div>
            <div css={[topicsListCss, topicsEndJustifyCss, topicsFadeAnimation(31.3025, 5.4725)]}>
              <p css={[topicsTitleCss, topicsTextCss, hBoldCss, blackTextCss]}>Topics:</p>
              <p css={[topicsTextCss, redTextCss]}>Inflation</p>
              <p css={[topicsTextCss, purpleTextCss]}>Federal Reserve</p>
            </div>
          </div>
        </div>
        <div css={paragraphContainerCss}>
          <div css={paragraphAndTopicsCss}>
            <div css={paragraphTextCss}>
              <svg width="100%" height="auto" viewBox="0 0 930 37">
                <rect
                  x="0"
                  y="12.5%"
                  width="98%"
                  height="75%"
                  css={[grayHightlightCss, highlightAnimation(98, 32.3025, 4.4825)]}
                />
                <text x="0" y="50%" css={[h4Body1Css, blackTextCss]} dominantBaseline="central">
                  While historically large, the expected rate increases represents a slowing from
                  the four
                </text>
              </svg>
              <svg width="100%" height="auto" viewBox="0 0 930 37">
                <rect
                  x="0"
                  y="12.5%"
                  width="99%"
                  height="75%"
                  css={[grayHightlightCss, highlightAnimation(99, 32.7925, 3.9875)]}
                />
                <text x="0" y="50%" css={[h4Body1Css, blackTextCss]} dominantBaseline="central">
                  separate 0.75-point rate increases by the Fed in recent months. Mr. Powell stated
                  that it
                </text>
              </svg>
              <svg width="100%" height="auto" viewBox="0 0 930 37">
                <rect
                  x="0"
                  y="12.5%"
                  width="97.5%"
                  height="75%"
                  css={[grayHightlightCss, highlightAnimation(97.5, 33.2875, 3.5)]}
                />
                <text x="0" y="50%" css={[h4Body1Css, blackTextCss]} dominantBaseline="central">
                  would make sense to slow rate increases as their impacts are felt across the
                  economy.
                </text>
              </svg>
            </div>
            <div css={[colorBlockContainerCss, topicsFadeAnimation(34.275, 2.5)]}>
              <div css={[edgeBlockCss, blueCss]} />
              <div css={middleBlockCss} />
              <div css={[edgeBlockCss, purpleCss]} />
            </div>
            <div css={[topicsListCss, topicsGapJustifyCss, topicsFadeAnimation(34.275, 2.5)]}>
              <p css={[topicsTitleCss, topicsTextCss, hBoldCss, blackTextCss]}>Topics:</p>
              <p css={[topicsTextCss, blueTextCss]}>Interest Rates</p>
              <p css={[topicsTextCss, purpleTextCss]}>Federal Reserve</p>
            </div>
          </div>
        </div>
      </div>
    ),
  },
]

export default classifySamples
