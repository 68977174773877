import {useState, useEffect} from 'react'

interface UseIsScrolledInBoundsProps<T extends HTMLElement> {
  setNode: (node: T | null) => void
  isScrolledInBounds: boolean
}

export default function useIsScrolledInBounds<T extends HTMLElement>(
  initialValue = false
): UseIsScrolledInBoundsProps<T> {
  const [isScrolledInBounds, setIsScrolledInBounds] = useState(initialValue)
  const [node, setNode] = useState<T | null>(null)

  useEffect(() => {
    if (!node) return undefined
    const observer = new IntersectionObserver(
      (entries) => {
        const {isIntersecting, boundingClientRect} = entries[0]
        const {top, height} = boundingClientRect
        setIsScrolledInBounds(top <= -height || isIntersecting)
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }
    )
    observer.observe(node)
    return () => {
      observer.unobserve(node)
    }
  }, [node])
  return {setNode, isScrolledInBounds}
}
