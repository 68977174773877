import {memo, useCallback, useEffect, useState} from 'react'
import {useTransition, animated, config} from '@react-spring/web'
import {css} from '@emotion/react'
import {useFuse} from '@kensho/tacklebox'
import FocusLock from 'react-focus-lock'
import {RemoveScroll} from 'react-remove-scroll'

import Portal from './Portal'

let openStack: HTMLDivElement[] = []

export interface OverlayProps {
  /**
   * Whether the backdrop should blur the background. Does nothing if `hasBackdrop` is false.
   *
   * @default false
   */
  blurBackdrop?: boolean

  /**
   * Whether pressing the escape key should invoke `onClose`.
   *
   * @default true
   */
  canEscapeKeyClose?: boolean

  /**
   * Whether clicking outside the overlay element (either on backdrop when present or on document)
   * should invoke `onClose`.
   *
   * @default true
   */
  canOutsideClickClose?: boolean

  /** Contents of the overlay. */
  children?: React.ReactNode

  /** Space-separated list of classes to pass to the underlying element. */
  className?: string

  /**
   * Whether the overlay should prevent focus from leaving itself.
   *
   * @default true
   */
  enforceFocus?: boolean

  /** Whether to render a backdrop that prevents interaction with background elements and visually hides background content.
   *
   * @default true
   */
  hasBackdrop?: boolean

  /** Whether the overlay is currently rendering visible content.
   *
   * @default false
   */
  isOpen?: boolean

  /** Whether to mount immediately or when it is opened for the first time.
   *
   * @default true
   */
  lazy?: boolean

  /** Callback to invoke when the overlay is closed. */
  onClose?: () => void

  /**
   * The element to focus when the overlay opens. If nothing is passed, focuses the first focusable element in the overlay content.
   * This prop can be used to focus the most common action (such as a "Continue" button), or the least destructive action in a process
   * that is not easily reversable (such as a "Cancel" button in a deletion modal).
   *
   * @see https://www.w3.org/TR/wai-aria-practices/#keyboard-interaction-7
   */
  onOpenFocusRef?: React.RefObject<HTMLElement>

  /**
   * Whether the overlay should prevent scrolling outside of its children.
   *
   * @default true
   */
  preventScroll?: boolean
}

/**
 * Renders content on top of the entire application.
 *
 * Content inside an Overlay is rendered through a Portal at the root of the DOM,
 * so it can be placed anywhere in the React tree.
 *
 * Overlay is a low-level component. Consumers are more likely to use
 * Dialog or Popover.
 *
 * @see Dialog for presenting windowed content on top of the application.
 * @see Popover for presenting overlaid content near a target.
 * @see Portal for context on how Overlay renders content at the root of the DOM.
 */
function Overlay(props: OverlayProps): JSX.Element | null {
  const {
    blurBackdrop = false,
    canEscapeKeyClose = true,
    canOutsideClickClose = true,
    children,
    className,
    enforceFocus = true,
    hasBackdrop = true,
    isOpen = false,
    lazy = true,
    onClose,
    onOpenFocusRef,
    preventScroll = true,
  } = props

  const [overlay, setOverlay] = useState<HTMLDivElement | null>(null)

  // handle returning focus after closing the Overlay
  useEffect(() => {
    if (!isOpen) return undefined
    const previouslyFocused = document.activeElement
    if (!(previouslyFocused instanceof HTMLElement)) return undefined
    return () => {
      previouslyFocused.focus()
    }
  }, [isOpen])

  // Set up event listeners for closing the Overlay
  useEffect(() => {
    if (overlay === null || !isOpen) return undefined
    openStack.push(overlay)
    const stackIndex = openStack.length - 1

    function handleClick(e: MouseEvent): void {
      // targets of MouseEvents should always be HTML Elements
      const eventTarget = e.target as HTMLElement
      const isClickInOverlay = openStack
        .slice(stackIndex)
        .some((node) => node.contains(eventTarget) && !node.isSameNode(eventTarget))
      if (isOpen && canOutsideClickClose && !isClickInOverlay) {
        onClose?.()
      }
    }

    // Add the event listener in the next tick so that the event that triggers
    // the overlay to open does not also trigger this event listener.
    const rafId = requestAnimationFrame(() => {
      document.addEventListener('click', handleClick)
    })

    return () => {
      openStack = openStack.slice(0, stackIndex)
      document.removeEventListener('click', handleClick)
      cancelAnimationFrame(rafId)
    }
  }, [canOutsideClickClose, isOpen, onClose, overlay])

  // Set up event listeners for handling the escape key
  useEffect(() => {
    if (!canEscapeKeyClose || !isOpen || overlay === null) return undefined
    const stackIndex = openStack.indexOf(overlay)
    function handleKeyDown(e: KeyboardEvent): void {
      // Escape should only close most recently opened overlay.
      if (e.key === 'Escape' && openStack.length - 1 === stackIndex) {
        onClose?.()
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [canEscapeKeyClose, isOpen, onClose, overlay])

  const hasEverBeenOpen = useFuse(isOpen)
  const transition = useTransition(isOpen, {
    config: config.stiff,
    initial: {opacity: 1, backdropFilter: `blur(${blurBackdrop ? 5 : 0}px)`},
    from: {opacity: 0, backdropFilter: 'blur(0px)'},
    enter: {opacity: 1, backdropFilter: `blur(${blurBackdrop ? 5 : 0}px)`},
    leave: {opacity: 0, backdropFilter: 'blur(0px)'},
  })

  const cssOverlay = css`
    overflow: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 15;
    pointer-events: none;
  `
  const cssOverlayBackdrop = css`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
    pointer-events: all;
  `

  const cssChildContainer = css`
    pointer-events: all;
  `

  const handleInitialFocus = useCallback(() => {
    onOpenFocusRef?.current?.focus()
  }, [onOpenFocusRef])

  if (lazy && !hasEverBeenOpen) return null
  return (
    <Portal>
      <RemoveScroll enabled={isOpen && preventScroll}>
        {transition((style, item) =>
          item ? (
            <animated.div css={cssOverlay} className={className} style={style}>
              {hasBackdrop && <div css={cssOverlayBackdrop} data-testid="overlay-backdrop" />}
              <div ref={setOverlay} css={cssChildContainer}>
                <FocusLock disabled={!enforceFocus || !isOpen} onActivation={handleInitialFocus}>
                  {children}
                </FocusLock>
              </div>
            </animated.div>
          ) : null
        )}
      </RemoveScroll>
    </Portal>
  )
}

export default memo(Overlay)
