import {css} from '@emotion/react'
import {IconCollapseHorizontal, IconInfo, IconTopBottom, IconX} from '@kensho/icons'
import {useState} from 'react'
import {wordErrorRate} from 'word-error-rate'

import Button from '../../../components/Button'
import {AnimatedContent, WideContent} from '../../../components/content'
import {BREAKPOINT_SMALL} from '../../../styles/breakpoints'
import COLORS from '../../../styles/colors'
import {baseSectionCss, h4Body1Css} from '../../../styles/common'

import {WER_CALCULATION_SECTION} from './constants'
import DiffText from './DiffText'

const PLACEHOLDER_TEST = 'Put the text you want to test here.'
const PLACEHOLDER_CORRECT = 'Put the ground truth text you want to compare against here.'

const ANIM_DURATION_SECONDS = 0.3
const TEXTAREA_HEIGHT_PX = 400

const calculatorCss = css`
  background: ${COLORS.white};
  border: 1px solid ${COLORS.gray[500]};
`

const calculatorHeaderCss = css`
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
  border-bottom: 1px solid ${COLORS.gray[500]};

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    flex-direction: column;
  }
`

const labelCss = css`
  padding: 20px 40px;
  font-size: 22px;
  font-weight: bold;
  transition: all ${ANIM_DURATION_SECONDS}s ease;
  transform: translateX(0);
  width: 50%;

  &:first-of-type {
    border-right: 1px solid ${COLORS.gray[500]};
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding: 10px;
    font-size: 18px;
  }
`

const disabledButtonCss = css`
  &:disabled {
    background: #acacac;
    cursor: not-allowed;
  }
`

const disabledOutlineButtonCss = css`
  &:disabled {
    border-color: #acacac;
    color: #acacac;
    cursor: not-allowed;
  }

  &:disabled:hover {
    background: transparent;
  }
`
const mergedLabelCss = css`
  opacity: 0;
  position: absolute;
  pointer-events: none;

  &:first-of-type {
    left: 0;
    transform: translateX(50%);
    border-right: none;
  }

  &:last-of-type {
    right: 0;
    transform: translateX(-50%);
  }
`

const werScoreCss = css`
  display: flex;
  align-items: center;
  transition: all 0.5s ease;
  opacity: 0;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-top: 10px;
  }
`

const werScoreVisibleCss = css`
  opacity: 1;
`

const iconInfoCss = css`
  height: 20px;
  width: 20px;
  margin-left: 10px;
`

const textAreasCss = css`
  display: flex;
  position: relative;
  justify-content: center;
  padding-bottom: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  height: ${TEXTAREA_HEIGHT_PX}px;
`

const mergedTextAreasCss = css`
  justify-content: center;
`

const textAreaCss = css`
  outline: none;
  border: none;
  resize: none;
  padding: 20px 0;
  width: 100%;
  height: 100%;
  white-space: wrap;
  display: block;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #444;

  &:invalid {
    border-color: red;
  }
`

const splitDiffCss = css`
  padding: 20px 0;
  font-weight: normal;
  font-size: 18px;
`

const mergedTextAreaCss = css`
  overflow: auto;
  height: ${TEXTAREA_HEIGHT_PX}px;
`

const boldCss = css`
  font-weight: bold;
`

const buttonsCss = css`
  display: flex;
  align-items: center;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding: 0 10px;
  }
`

const unifiedDiffLabelCss = css`
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 20px;
`

const unifiedDiffCss = css`
  font-size: 18px;
  opacity: 0;
  width: 0;
  transition: none;
  overflow: hidden;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding: 10px;
  }
`

const unifiedDiffVisibleCss = css`
  opacity: 1;
  width: 50%;
  transition: opacity 0.9s ${ANIM_DURATION_SECONDS - 0.5}s ease;
  overflow: auto;
  padding: 20px 40px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: 100%;
  }
`

const buttonCss = css`
  margin: 0 10px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: 95%;
    text-align: center;
    padding: 10px;
  }
`

const clearButtonsCss = css`
  display: flex;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: 98%;
  }
`

const clearIconCss = css`
  margin-left: 10px;
`

const splitViewIconCss = css`
  transform: rotate(90deg);
`

const viewButtonCss = css`
  width: 50px;
  padding: 10px;
`

const hiddenCss = css`
  display: none;
`

export default function WERCalculator(): JSX.Element {
  const [testText, setTestText] = useState(PLACEHOLDER_TEST)
  const [correctText, setCorrectText] = useState(PLACEHOLDER_CORRECT)
  const [unifiedView, setUnifiedView] = useState(false)
  const [wer, setWer] = useState<number | null>(null)

  return (
    <section>
      <WideContent css={baseSectionCss}>
        <AnimatedContent css={h4Body1Css}>
          <div css={calculatorCss}>
            <header css={calculatorHeaderCss}>
              <div css={buttonsCss}>
                <Button
                  css={[buttonCss, disabledButtonCss]}
                  disabled={testText === '' || correctText === ''}
                  variant="primary"
                  onClick={() => {
                    setWer(
                      wordErrorRate(
                        // normalize punctuation and whitespace before calculating WER
                        testText.replace(/[!?,.]/g, '').replace(/\s{2,}/g, ' '),
                        correctText.replace(/[!?,.]/g, '').replace(/\s{2,}/g, ' ')
                      ) * 100
                    )
                  }}
                >
                  Calculate
                </Button>
                <div css={clearButtonsCss}>
                  <Button
                    css={[buttonCss, disabledOutlineButtonCss]}
                    variant="secondary"
                    disabled={testText === '' && correctText === ''}
                    onClick={() => {
                      setWer(null)
                      setTestText('')
                      setCorrectText('')
                    }}
                  >
                    Clear <IconX css={clearIconCss} title="Clear" />
                  </Button>
                  {wer !== null && (
                    <>
                      <Button
                        css={[buttonCss, disabledOutlineButtonCss]}
                        variant="secondary"
                        onClick={() => {
                          setWer(null)
                        }}
                      >
                        Reset
                      </Button>
                      <Button
                        css={[buttonCss, viewButtonCss, disabledOutlineButtonCss]}
                        variant="secondary"
                        onClick={() => setUnifiedView(!unifiedView)}
                      >
                        {unifiedView ? (
                          <IconTopBottom
                            css={splitViewIconCss}
                            title="Switch to split view"
                            size={22}
                          />
                        ) : (
                          <IconCollapseHorizontal title="Switch to unified view" size={22} />
                        )}
                      </Button>
                    </>
                  )}
                </div>
              </div>
              <div css={[werScoreCss, wer !== null && werScoreVisibleCss]}>
                <p css={boldCss}>Word Error Rate {`${(wer || 0).toFixed(2)}%`}</p>
                <a href={`#${WER_CALCULATION_SECTION}`}>
                  <IconInfo css={iconInfoCss} title="How is this calculated?" />
                </a>
              </div>
            </header>

            <div css={[textAreasCss, wer !== null && unifiedView && mergedTextAreasCss]}>
              <label css={[labelCss, wer !== null && unifiedView && mergedLabelCss]}>
                Comparison Text
                <textarea
                  placeholder={PLACEHOLDER_TEST}
                  css={[textAreaCss, wer !== null && (unifiedView ? mergedTextAreaCss : hiddenCss)]}
                  value={testText}
                  onChange={(e) => setTestText(e.target.value)}
                />
                {wer !== null && !unifiedView && (
                  <div css={splitDiffCss}>
                    <DiffText leftText={testText} rightText={correctText} mode="removals" />
                  </div>
                )}
              </label>

              <article css={[unifiedDiffCss, wer !== null && unifiedView && unifiedDiffVisibleCss]}>
                <p css={[h4Body1Css, boldCss, unifiedDiffLabelCss]}>Text Differences</p>
                <DiffText leftText={testText} rightText={correctText} />
              </article>

              <label css={[labelCss, wer !== null && unifiedView && mergedLabelCss]}>
                Correct Text
                <textarea
                  placeholder={PLACEHOLDER_CORRECT}
                  css={[textAreaCss, wer !== null && (unifiedView ? mergedTextAreaCss : hiddenCss)]}
                  value={correctText}
                  onChange={(e) => setCorrectText(e.target.value)}
                />
                {wer !== null && !unifiedView && (
                  <div css={splitDiffCss}>
                    <DiffText leftText={testText} rightText={correctText} mode="additions" />
                  </div>
                )}
              </label>
            </div>
          </div>
        </AnimatedContent>
      </WideContent>
    </section>
  )
}
