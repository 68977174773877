import {css} from '@emotion/react'
import {useMemo, useState} from 'react'

import {AnimatedContent, WideContent} from '../../components/content'
import {HeadingContent} from '../../components/headings'
import {ShortTextContent} from '../../components/textContent'
import Button from '../../components/Button'
import {
  backgroundLightGrayCss,
  baseSectionCss,
  hBoldCss,
  largeButtonCss,
  sectionHeadingMarginCss,
} from '../../styles/common'
import {BREAKPOINT_MEDIUM} from '../../styles/breakpoints'

import Publication, {PublicationProps} from './Publication'

const publicationsContainerCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const shortTextCss = css`
  padding: 0;
  margin-bottom: 64px;

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    padding: 0;
  }
`

const pubListContainerCss = css`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #9da1a6;
  max-width: 910px;
`

const authorTextCss = css`
  font-size: 14px;
`

const buttonCss = css`
  max-width: 580px;
  width: 100%;
  margin-top: 64px;
`

const publicationList: PublicationProps[] = [
  {
    title: 'BizBench: A Quantitative Reasoning Benchmark for Business and Finance',
    url: 'https://arxiv.org/abs/2311.06602',
    authors: (
      <p css={[authorTextCss, hBoldCss]}>
        Michael Krumdick, Rik Koncel-Kedziorski, Viet Dac Lai, Varshini Reddy, Charles Lovering,
        Chris Tanner
      </p>
    ),
    publisher: 'ACL - 2024',
  },
  {
    title: 'DocFinQA: A Long-Context Financial Reasoning Dataset',
    url: 'https://arxiv.org/abs/2401.06915',
    authors: (
      <p css={[authorTextCss, hBoldCss]}>
        Varshini Reddy, Rik Koncel-Kedziorski, Viet Dac Lai, Michael Krumdick, Charles Lovering,
        Chris Tanner
      </p>
    ),
    publisher: 'ACL - 2024',
  },
  {
    title: 'Greed is All You Need: An Evaluation of Tokenizer Inference Methods',
    url: 'https://arxiv.org/abs/2403.01289',
    authors: (
      <p css={authorTextCss}>
        Omri Uzan, <span css={hBoldCss}>Craig W Schmidt, Chris Tanner</span>, Yuval Pinter
      </p>
    ),
    publisher: 'ACL - 2024',
  },
  {
    title: 'MCECR: A Novel Dataset for Multilingual Cross-Document Event Coreference Resolution',
    url: 'https://aclanthology.org/2024.findings-naacl.245/',
    authors: (
      <p css={authorTextCss}>
        Amir Pouran Ben Veyseh, <span css={hBoldCss}>Viet Dac Lai</span>, et al.
      </p>
    ),
    publisher: 'NAACL (Findings) - 2024',
  },
  {
    title:
      'CulturaX: A Cleaned, Enormous, and Multilingual Dataset for Large Language Models in 167 Languages',
    url: 'https://arxiv.org/abs/2309.09400',
    authors: (
      <p css={authorTextCss}>
        Thuat Nguyen, Chien Van Nguyen, <span css={hBoldCss}>Viet Dac Lai</span>, et al.
      </p>
    ),
    publisher: 'LREC-COLING - 2024',
  },
  {
    title: 'CAMAL: A Novel Dataset for Multi-label Conversational Argument Move Analysis',
    url: 'https://aclanthology.org/2024.lrec-main.239/',
    authors: (
      <p css={authorTextCss}>
        <span css={hBoldCss}>Viet Dac Lai</span>, et al.
      </p>
    ),
    publisher: 'LREC-COLING - 2024',
  },
  {
    title: 'Using Machine Learning to Detect Student Learning Levels along a Learning Progression',
    url: 'https://laiviet.github.io/papers/NCME_2024_Learning_Progress.pdf',
    authors: (
      <p css={authorTextCss}>
        Duy Pham, <span css={hBoldCss}>Viet Dac Lai</span>
      </p>
    ),
    publisher: 'NCME - 2024',
  },
  {
    title: 'Scalable Neural Network Kernels',
    url: 'https://arxiv.org/abs/2310.13225',
    authors: (
      <p css={authorTextCss}>
        <span css={hBoldCss}>Arijit Sehanobish</span>, Krzysztof Choromanski, Yunfan Zhao, Avinava
        Dubey, Valerii Likhosherstov
      </p>
    ),
    publisher: 'ICLR - 2024',
  },
  {
    title: 'The economic trade-offs of large language models: A case study',
    url: 'https://aclanthology.org/2023.acl-industry.24.pdf',
    authors: (
      <p css={authorTextCss}>
        <span css={hBoldCss}>Kristen Howell</span>, Gwen Christian, Pavel Fomitchov, Gitit Kehat,
        Julianne Marzulla, Leanne Rolston, Jadin Tredup, Ilana Zimmerman, Ethan Selfridge, Joseph
        Bradley
      </p>
    ),
    publisher: 'ACL (industry track) - 2023',
  },
  {
    title: 'A Graphical Approach to Document Layout Analysis',
    url: 'https://arxiv.org/abs/2308.02051',
    authors: (
      <p css={[authorTextCss, hBoldCss]}>
        Jilin Wang, Michael Krumdick, Baojia Tong, Hamima Halim, Maxim Sokolov, Vadym Barda,
        Delphine Vendryes, Chris Tanner
      </p>
    ),
    publisher: 'ICDAR - 2023',
  },
  {
    title: 'GriTS: Grid Table Similarity Metric for Table Structure Recognition',
    url: 'https://arxiv.org/abs/2203.12555',
    authors: (
      <p css={authorTextCss}>
        <span css={hBoldCss}>Brandon Smock</span>, Rohith Pesala, Robin Abraham
      </p>
    ),
    publisher: 'ICDAR - 2023',
  },
  {
    title: 'What happens before and after: Multi-Event Commonsense in Event Coreference Resolution',
    url: 'https://arxiv.org/abs/2302.09715',
    authors: (
      <p css={authorTextCss}>
        Sahithya Ravi, <span css={hBoldCss}>Chris Tanner</span>, Raymond Ng, Vered Shwartz
      </p>
    ),
    publisher: 'EACL - 2023',
  },
  {
    title: 'Aligning Benchmark Datasets for Table Structure Recognition',
    url: 'https://arxiv.org/abs/2303.00716',
    authors: (
      <p css={authorTextCss}>
        <span css={hBoldCss}>Brandon Smock</span>, Rohith Pesala, Robin Abraham
      </p>
    ),
    publisher: 'ICDAR - 2023',
  },
  {
    title:
      'Learning Answer Generation using Supervision from Automatic Question Answering Evaluators.',
    url: 'https://arxiv.org/abs/2305.15344',
    authors: (
      <p css={authorTextCss}>
        Matteo Gabburo, Siddhant Garg, <span css={hBoldCss}>Rik Koncel-Kedziorski</span>, Alessandro
        Moschitti
      </p>
    ),
    publisher: 'ACL - 2023',
  },
  {
    title: 'Efficient Graph Field Integrators Meet Point Clouds',
    url: 'https://arxiv.org/abs/2302.00942',
    authors: (
      <p css={authorTextCss}>
        Krzysztof Choromanski, <span css={hBoldCss}>Arijit Sehanobish</span>, et al.
      </p>
    ),
    publisher: 'ICML - 2023',
  },
  {
    title: 'Boosting Punctuation Restoration with Data Generation and Reinforcement Learning',
    url: 'https://arxiv.org/abs/2307.12949',
    authors: (
      <p css={authorTextCss}>
        <span css={hBoldCss}>Viet Dac Lai</span>, et al.
      </p>
    ),
    publisher: 'INTERSPEECH - 2023',
  },
  {
    title: 'Mask Conditional Synthetic Satellite Imagery',
    url: 'https://arxiv.org/abs/2302.04305',
    authors: (
      <p css={authorTextCss}>
        Van Anh Le, <span css={hBoldCss}>Varshini Reddy</span>, et al.
      </p>
    ),
    publisher: 'ICLR - 2023',
  },
]

export default function PublicationSection(): JSX.Element {
  const [expanded, setExpanded] = useState(false)
  const publications = useMemo(
    () => (expanded ? publicationList : publicationList.slice(0, 9)),
    [expanded]
  )

  return (
    <section css={[baseSectionCss, backgroundLightGrayCss]}>
      <WideContent css={publicationsContainerCss}>
        <HeadingContent headerText="Publications" css={sectionHeadingMarginCss} />
        <ShortTextContent
          text="Learn more about our research through our publications."
          css={shortTextCss}
        />
        <AnimatedContent>
          <div css={pubListContainerCss}>
            {publications.map(({title, url, authors, publisher}) => (
              <Publication
                key={title}
                title={title}
                url={url}
                authors={authors}
                publisher={publisher}
              />
            ))}
          </div>
        </AnimatedContent>
        {!expanded && (
          <AnimatedContent animation="fade-in">
            <Button
              css={[buttonCss, largeButtonCss]}
              variant="secondary"
              type="button"
              onClick={() => {
                setExpanded(true)
              }}
            >
              Load More
            </Button>
          </AnimatedContent>
        )}
      </WideContent>
    </section>
  )
}
