import {Component} from 'react'
import {Logger, useLogger} from '@kensho/lumberjack'

import Section from './Section'

interface ErrorBoundaryProps {
  children: React.ReactNode
  log: Logger
}

interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public state: ErrorBoundaryState = {
    hasError: false,
  }

  public componentDidCatch(error: Error, info: React.ErrorInfo): void {
    const {log} = this.props
    this.setState({hasError: true})
    log.error(error, info)
  }

  public render(): React.ReactNode {
    const {children} = this.props
    const {hasError} = this.state
    if (!hasError) return children
    return (
      <Section title="Oops">
        <p>Something went wrong.</p>
      </Section>
    )
  }
}

export default function ErrorBoundaryWithLogger(
  props: Omit<ErrorBoundaryProps, 'log'>
): JSX.Element {
  const log = useLogger()
  return <ErrorBoundary {...props} log={log} />
}
