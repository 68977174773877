import {css} from '@emotion/react'

export default css`
  * {
    box-sizing: border-box;
  }

  html {
    background-color: #edf0f2;
    color: #444;
    font: 13px/1.6 'proxima-nova', 'Helvetica', 'Arial', sans-serif;
  }

  ::selection {
    background: rgba(180, 180, 180, 0.2);
  }

  p {
    margin: 0;
  }

  a {
    text-decoration: none;
    color: #444;
    transition: all 200ms;

    p & {
      color: #50afc6;
    }

    &:hover {
      opacity: 0.6;
    }
  }

  h1,
  h2,
  h3,
  h4 {
    margin: 0;
    font-weight: 500;
  }

  h1,
  h2 {
    font-weight: 400;
  }

  h1 {
    color: #50afc6;
    font-size: 6rem;
    text-transform: uppercase;
    letter-spacing: 0.06em;
    line-height: 1em;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 4rem;
    }
  }

  h2 {
    font-size: 2.2rem;
    text-align: center;
  }

  h3 {
    font-size: 1.3rem;
    font-weight: 400;
  }

  h4 {
    font-size: 0.95rem;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.14em;
  }

  b {
    font-weight: 600;
  }

  button {
    transition: all 200ms;
    display: block;
    background: none;
    outline: none;
    font: inherit;
    cursor: pointer;
    border: 1px solid #eee;
    padding: 8px 16px;
  }

  button:hover {
    background: #eee;
  }
`
