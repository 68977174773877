import {useEffect, useState} from 'react'
import {css} from '@emotion/react'

import COLORS from '../../../styles/colors'
import {legalCss} from '../../../styles/common'

interface DurationProps {
  initialCount?: number
  animate?: boolean
  animateDuration?: number
}

const numberCss = css`
  color: ${COLORS.brand.light[300]};
`

function easeOutSine(x: number): number {
  return Math.sin((x * Math.PI) / 2)
}

/** Counts time number at the specified interval and rate. Matches real time by default. */
export default function Number({
  initialCount = 0,
  animate = false,
  animateDuration = 1500,
}: DurationProps): JSX.Element {
  const [number, setNumber] = useState(animate ? 0 : initialCount)
  const [enterAnimationCompleted, setEnterAnimationCompleted] = useState(!animate)

  useEffect(() => {
    let animateInterval: number
    if (animate && !enterAnimationCompleted) {
      const startMs = Date.now()
      animateInterval = window.setInterval(() => {
        const elapsedMs = Date.now() - startMs
        if (elapsedMs >= animateDuration) setEnterAnimationCompleted(true)
        setNumber(
          Math.floor(
            initialCount * easeOutSine(Math.min(elapsedMs, animateDuration) / animateDuration)
          )
        )
      }, 1000 / 30)
    }

    return () => {
      window.clearInterval(animateInterval)
    }
  }, [animate, animateDuration, initialCount, enterAnimationCompleted])

  return <p css={[numberCss, legalCss]}>{number}</p>
}
