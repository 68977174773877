import {css} from '@emotion/react'
import {groupBy} from 'lodash'

import Link from '../../components/Link'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import {h4Body2Css, labelCss, hBoldCss} from '../../styles/common'

const categoriesToLinks: {[key: string]: string | undefined} = {
  'Analytics Software': '/industry/analytics',
  'Applied Mind': '/industry/applied-mind',
}

export interface LeverJob {
  text: string
  hostedUrl: string
  jobLink: string
  categories: {
    department: string
    location: string
    team: string
    allLocations: string[]
  }
}

interface CategoryProps {
  name: string
  jobs: LeverJob[]
}

const categoryCss = css`
  width: 450px;
  margin-bottom: 40px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    width: 320px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: 450px;
  }
`

const categoryNameCss = css`
  font-size: 30px;
`

const linkLabelCss = css`
  &:hover {
    opacity: 0.6;
  }
`

const lighterTextCss = css`
  color: #5d5d5d;
`

const jobCss = css`
  margin: 20px 0;
`

const jobTextCss = css`
  text-transform: none;
  letter-spacing: initial;
`

const jobLinkCss = css`
  display: inline-block;
  position: relative;
  color: #50afc6;
  margin: 0 15px;

  &:not(:last-of-type):after {
    content: '|';
    position: absolute;
    right: -15px;
    color: #5d5d5d;
  }
`

// Clones jobs for each of allLocations and dedupes between locations so that jobs can be
// correctly counted and sorted
// Frequently disables no-param-reassign to make reduce operations cheaper and simpler.
function flattenAndDedupeJobLocations(byJob: Record<string, LeverJob[]>): [string, LeverJob[]][] {
  const byJobAndLocation = Object.entries(byJob).reduce<Record<string, Record<string, LeverJob>>>(
    (map, [text, jobs]) => {
      if (!map[text]) {
        // eslint-disable-next-line no-param-reassign
        map[text] = {}
      }
      jobs.forEach((job) => {
        // Enforce that the map contains the primary location for the job, overwriting any other
        // listings

        // eslint-disable-next-line no-param-reassign
        map[text][job.categories.location] = job

        // For each secondary location, only add the listing if it has not already
        // been filled.
        job.categories.allLocations.forEach((location) => {
          if (!map[text][location]) {
            // eslint-disable-next-line no-param-reassign
            map[text][location] = {...job, categories: {...job.categories, location}}
          }
        })
      })
      return map
    },
    {}
  )

  // then convert the inner maps back into an array of job listings
  return Object.entries(byJobAndLocation).map(([text, jobs]) => [text, Object.values(jobs)])
}

export default function Category({jobs, name}: CategoryProps): JSX.Element {
  // combine same job in different locations
  const byJob = groupBy(jobs, (job) => job.text)

  // clone all jobs with secondary locations and dedupe by location, so that we can
  // include all locations for each job while supporting both `location` and `allLocations`.
  const dedupedJobs = flattenAndDedupeJobLocations(byJob)

  const sortedJobs = dedupedJobs.sort(([jobAText], [jobBText]) => jobAText.localeCompare(jobBText))

  const link = categoriesToLinks[name]

  return (
    <div css={categoryCss}>
      {link ? (
        <Link to={link}>
          <h3 css={[categoryNameCss, linkLabelCss]}>{name}</h3>
        </Link>
      ) : (
        <h3 css={[categoryNameCss, lighterTextCss]}>{name}</h3>
      )}
      <div>
        {sortedJobs.map(([jobText, jobListings]) => (
          <div css={jobCss} key={jobText}>
            <h4 css={[jobTextCss, h4Body2Css]}>{jobText}</h4>

            {jobListings
              .sort((jobA, jobB) =>
                jobA.categories.location.localeCompare(jobB.categories.location)
              )
              .map((job) => (
                <Link
                  css={[jobLinkCss, labelCss, hBoldCss]}
                  to={job.hostedUrl}
                  key={job.categories.location}
                >
                  {job.categories.location}
                </Link>
              ))}
          </div>
        ))}
      </div>
    </div>
  )
}
