// eslint-disable-next-line import/prefer-default-export
export function groupBy<T>(array: T[] | null, fn: (item: T) => string): {[index: string]: T[]} {
  const result: {[index: string]: T[]} = {}
  if (!array) return result
  array.forEach((el) => {
    const val = fn(el)
    if (Array.isArray(result[val])) result[val].push(el)
    else result[val] = [el]
  })
  return result
}
